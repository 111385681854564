.blogs-page {
    padding: 20px;

    .search-sort {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        input {
            padding: 10px;
            width: 70%;
            border-radius: 5px;
            border: 1px solid #ccc;
        }

        select {
            padding: 10px;
            width: 30%;
            border-radius: 5px;
            border: 1px solid #ccc;
        }
    }

    .blog-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    .blog-card {
        width: 30%;
        background-color: #f9f9f9;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    }

    .blog-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
    }

    .blog-content {
        margin-top: 10px;

        h3 {
            font-size: 1.2rem;
            font-weight: bold;
        }

        p {
            font-size: 0.9rem;
            color: #555;
        }

        .see-more-button {
            display: inline-block;
            padding: 10px;
            background-color: #007bff;
            color: white;
            text-decoration: none;
            border-radius: 5px;
            margin-top: 10px;
        }

        .blog-actions {
            display: flex;
            justify-content: space-around;
            margin-top: 10px;
        }
    }
}